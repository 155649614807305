// Radio Buttons
@import 'mixins';

.rs-border-left {
  border-left: 2px solid var(--rs-ds--border-color);
}

button.mat-mdc-icon-button.mat-mdc-button-base[aria-label='Clear'] {
  height: 30px;
  width: 30px;
  padding: 0;

  .mat-icon {
    font-size: 18px;
    height: 18px;
    line-height: 18px;
  }
}

.rs-divider {
  border-bottom: 1px solid var(--rs-ds--border-color);
}

$size: 32px;

.txt-circle {
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  border-radius: 50%;
}

.txt-circle-fill {
  @extend .txt-circle;
  background-color: var(--rs-ds--color--primary);
  color: white;
}

.txt-circle-outline {
  @extend .txt-circle;
  border: 1px solid #000;
}

.inactive svg path {
  fill: var(--rs-color--grey--200);
}

.inactive {
  color: var(--rs-color--grey--50);
}

.active svg path {
  fill: var(--rs-color--grey--500);
}

.mat-icon {
  &.green {
    color: var(--rs-color--green--600);
  }

  &.back-arrow {
    transform: rotate(180deg);
  }
}

.mat-expansion-panel-spacing {
  margin: 0;
}

main.container {
  margin-top: 60px;
}

.mrt-layout-add-page {
  display: flex;
  justify-content: center;
  padding-top: 2.5rem !important;

  > :first-child {
    width: 80%;

    @media (max-width: $md) {
      width: 100%;
    }

    @media (min-width: $xl) {
      width: 66.66666667%;
    }
  }
}
